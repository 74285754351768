const config = {
  // hu / pl / cz / fr
  lang: "hu",
  hreflang: "hu-HU",
  hreflangEN: "en-GB",
  grecaptcha: "6Le_HpUeAAAAAPjPpb__lZacSox6GzbJPhOMG_NM",
  domain: "https://franchise.pizzahut.hu/",

  benefitsURL: "/elonyok",
  procesURL: "/folyamat",
  contactURL: "/kapcsolat",
};
export default config;
