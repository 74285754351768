import { withRouter } from "react-router-dom";
import { getLanguage } from "../i18n/Translations";
import config from "global.config";
import { virtualVersion } from "../../DataLayer";

let added = false;
let language = getLanguage();

const benefits = config.benefitsURL;
const process = config.procesURL;
const contact = config.contactURL;

function FakeRouter({ history }) {
  virtualVersion();

  const switchToEn = (url) => {
    switch (url) {
      case "/":
        history.push("/en/");
        break;
      case contact:
        history.push("/en/contact");
        break;
      case benefits:
        history.push("/en/benefits");
        break;
      case process:
        history.push("/en/how-to-start");
        break;

      default:
        break;
    }
  };

  const switchToPl = (url) => {
    switch (url) {
      case "/en/":
        history.push("/");
        break;
      case "/en/contact":
        history.push(contact);
        break;
      case "/en/benefits":
        history.push(benefits);
        break;
      case "/en/how-to-start":
        history.push(process);
        break;

      default:
        break;
    }
  };

  if (language !== getLanguage()) {
    added = false;
    language = getLanguage();

    if (language === config.lang) {
      switchToPl(window.location.pathname);
    } else {
      switchToEn(window.location.pathname);
    }
  }

  if (!added) {
    history.listen((location) => {
      if (language === "en") {
        let url = location.pathname;
        switchToEn(url);
      }
    });

    added = true;
  }

  return <></>;
}

export default withRouter(FakeRouter);
