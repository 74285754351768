import { useEffect, useState } from "react";

export const useMobile = (media = 900) => {
	const [width, setWidth] = useState(window.innerWidth);
	const windowSizeHandler = () => {
		setWidth(window.innerWidth);
	};
	useEffect(() => {
		window.addEventListener("resize", windowSizeHandler);
		return () => window.removeEventListener("resize", windowSizeHandler);
	}, []);
	return width <= media;
};
