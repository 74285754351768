import { useEffect, useState, lazy, Suspense } from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Header from "./v2/components/layout/Header";

import { Container } from "react-bootstrap";
import UTM from "./v2/models/UTM";
import FakeRouter from "./v2/components/FakeRouter";
import { Helmet } from "react-helmet";

import { changeLanguage } from "./v2/i18n/Translations";
import { abTest } from "./PiwikDataLayer";

import config from "global.config";

const benefits = config.benefitsURL;
const process = config.procesURL;
const contact = config.contactURL;

const Form = lazy(() => import("./v2/pages/Form"));
const Process = lazy(() => import("./v2/pages/Process"));
const Benefits = lazy(() => import("./v2/pages/Benefits"));
const Footer = lazy(() => import("./v2/components/layout/Footer"));
const Home = lazy(() => import("./v2/pages/Home"));

const App = () => {
  const [key, setKey] = useState(1);

  const rerender = (key) => {
    setKey(key);
  };

  useEffect(() => {
    if (window.location.pathname.includes("en")) {
      changeLanguage("en");
    }

    const utm = new UTM();
    utm.store();
    abTest();
  }, []);

  return (
    <Suspense fallback={<div></div>}>
      <Container id={"app-container"} fluid={true} className={"p-0 m-0"} key={key}>
        <BrowserRouter>
          <Switch>
            <Route exact path={[contact, "/en/contact"]}>
              <Header const rerender={rerender} />
              <Form />
              <Footer />
            </Route>
            <Route exact path={[benefits, "/en/benefits"]}>
              <Header rerender={rerender} />
              <Benefits />
              <Footer />
            </Route>
            <Route exact path={[process, "/en/how-to-start"]}>
              <Header rerender={rerender} />
              <Process />
              <Footer />
            </Route>
            <Route exact path={["/", "/en/"]}>
              <Header rerender={rerender} />
              <Home />
              <Footer />
            </Route>
            <Route>
              <Helmet>
                <meta name="robots" content="noindex" />
              </Helmet>
            </Route>
          </Switch>
          <FakeRouter />
        </BrowserRouter>
      </Container>
    </Suspense>
  );
};

export default App;
