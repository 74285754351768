import React, { useState, useEffect } from "react";
import { Container, Dropdown, Nav, Navbar, Image } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";
// import "bootstrap/dist/css/bootstrap.min.css";
import "../../App.scss";

import Translations from "../../i18n/Translations";
import { changeLanguage, getLanguage } from "../../i18n/Translations";
import { useMobile } from "../../helpers/useMobile";
import config from "global.config";

const Header = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isLandscape, setIsLandscape] = useState(false);
  const isMobile = useMobile();

  const changeMode = () => {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    const height = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    setIsLandscape(width > height);
  };

  const languageClick = (e, to) => {
    e.preventDefault();
    changeLanguage(to);
    props.rerender(Math.random());
  };

  useEffect(() => {
    changeMode();
    window.addEventListener("resize", changeMode);
    return () => window.removeEventListener("resize", changeMode);
  }, []);

  return (
    <Navbar id="menu" expand="lg">
      <Container className={"navbar-container p-0"}>
        <Link to={getLanguage() === "hu" ? "/" : "/en/"} style={{ textDecoration: "none" }}>
          <Navbar.Brand className={"p-sm-10"}>
            <Image
              style={{ marginLeft: "8px" }}
              src={
                isMobile ? "https://amrestcdn.azureedge.net/ph-web-ordering/franchise-hu/home/icons/logoMobile.svg" : "https://amrestcdn.azureedge.net/ph-web-ordering/franchise-hu/PizzaHutLogo.svg"
              }
            />
            <span>{Translations["layout_heading"]}</span>
          </Navbar.Brand>
        </Link>
        <Navbar.Collapse className="justify-content-end">
          <Nav>
            <NavLink exact to={getLanguage() === "hu" ? "/" : "/en/"} className="menu-link" activeClassName="menu-link-selected">
              {Translations["layout_menu_main"]}
            </NavLink>
            <NavLink to={Translations["url_benefits"]} className="menu-link" activeClassName="menu-link-selected">
              {Translations["layout_menu_benefits"]}
            </NavLink>
            <NavLink to={Translations["url_how_to_start"]} className="menu-link" activeClassName="menu-link-selected">
              {Translations["layout_menu_process"]}
            </NavLink>
            <NavLink to={Translations["url_contact"]} className="menu-link" activeClassName="menu-link-selected">
              {Translations["layout_menu_join"]}
            </NavLink>
          </Nav>
        </Navbar.Collapse>

        <img
          alt=""
          style={{
            paddingRight: "18px",
            cursor: "pointer",
          }}
          src={"https://amrestcdn.azureedge.net/ph-web-ordering/franchise-hu/home/icons/menu.svg"}
          className={"mobile-menu-show"}
          onClick={() => setIsOpen(true)}
        />

        <div className={" mobile-menu " + (isOpen ? "open" : "closed")} onClick={(e) => setIsOpen(false)}>
          <img
            alt=""
            style={{
              position: "absolute",
              cursor: "pointer",
              top: "18px",
              right: "18px",
            }}
            src={"https://amrestcdn.azureedge.net/ph-web-ordering/franchise-pl/layout/menu_open.png"}
          />

          <Nav className={"navs " + (isLandscape ? "landscape" : "portrait")}>
            <Link to={"/"}>{Translations["layout_menu_main"]}</Link>
            <Link to={Translations["url_benefits"]}>{Translations["layout_menu_benefits"]}</Link>
            <Link to={Translations["url_how_to_start"]}>{Translations["layout_menu_process"]}</Link>
            <Link to={Translations["url_contact"] + "#form-page"}>{Translations["layout_menu_join"]}!</Link>

            <div id={"mobile-languages"}>
              <span className={getLanguage() === config.lang ? "active" : ""} onClick={(e) => languageClick(e, config.lang)}>
                {Translations["language_pl"]}
              </span>
              <span className={getLanguage() === "en" ? "active" : ""} onClick={(e) => languageClick(e, "en")}>
                {Translations["language_en"]}
              </span>
            </div>
          </Nav>
        </div>
        <Dropdown>
          <Dropdown.Toggle id="dropdown-language">{getLanguage()}</Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item href="#" className={getLanguage() === config.lang ? "active" : ""} onClick={(e) => languageClick(e, config.lang)}>
              {Translations["language_pl"]}
            </Dropdown.Item>
            <Dropdown.Item href="#" className={getLanguage() === "en" ? "active" : ""} onClick={(e) => languageClick(e, "en")}>
              {Translations["language_en"]}
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </Container>
    </Navbar>
  );
};

export default Header;
