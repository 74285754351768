export default class UTM {
  store() {
    let result = {};
    let tmp = [];
    window.location.search
      .substr(1)
      .split("&")
      .forEach((item) => {
        tmp = item.split("=");
        if (tmp[0].startsWith('utm_')) {
          result[tmp[0]] = decodeURIComponent(tmp[1]);
        }
      });


    if (result && Object.keys(result).length) {
      window.localStorage.setItem('UTM', JSON.stringify(result));
    }
  }

  get() {
    let string = window.localStorage.getItem('UTM');
    return string ? JSON.parse(string) : null;
  }

  unset() {
    window.localStorage.removeItem('UTM');
  }
}
